import React from 'react'

const NotificationElement = (props) => {

  return (
    <div className={`notification ${props.type}`}
         onClick={props.closeHandler}
         onAnimationEnd={props.closeHandler}>
      <div className='notification-popup-text' dangerouslySetInnerHTML={{ __html: props.text }}></div>
    </div>
  )
}

export default NotificationElement
