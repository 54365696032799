import React from 'react'

import logo from './assets/howtoplay.png'
import nowLive from './assets/now-live.png'
import './learn.css'

function Learn () {

  return (
    <>
      <section className="header-section learn-header">
        <img className="header-logo" src={logo} alt="That's Wizard"/>
        {/*<a href="https://www.kickstarter.com/projects/matthewsorrentino/thats-wizard" target="_blank">
          <img className="now-live-logo" src={nowLive} alt="Now Live!" />
        </a>*/}
      </section>

      <div className='content-bg'>
        <div className="content-border">
        <div className="section-container">

          <section className="learn-sections">

            <h2 className="header-text">Basic Rules</h2>

            <div className="regular-text">
              This page is under construction for the latest changes. Please refer to the rulebooks below to learn how to play.
            </div>

            <h2 className="header-text">Rulebooks</h2>

            <div className="regular-text">
               <a href="/That's Wizard Rulebook.pdf" target="_blank" download>That's Wizard!</a>
            </div>

            <div className="regular-text">
              <a href="/Arcana Rulebook.pdf" target="_blank" download>That's Wizard! Arcana Expansion</a>
            </div>

          </section>

        </div>
        </div>
      </div>

    </>
  )
}

export default Learn
