import React, {useContext, useState, useEffect, useRef, useCallback} from 'react';
import {useParams, useHistory, useLocation} from "react-router-dom";
import { GlobalContext } from '../../app'
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import Options from '../../components/options/options'
import {useThrottle, useThrottleCallback} from '@react-hook/throttle'
import logo from './assets/spellbook.png'
import seven from './assets/seven-cards.png'
import requirements from './assets/spell-requirements.png'
import duplicates from './assets/spell-duplicates.png'
import nowLive from './assets/now-live.png'
import { isMobile } from "react-device-detect"



import './deck.css'
import SpellAnimationElement from "./spellAnimationElement";
import SpellCard from "./spellCard";


function Deck () {
  let params = useParams()
  let history = useHistory()
  let query = new URLSearchParams(useLocation().search)

  useEffect(()=> {
    let yourbookCode = query.get("yourbook")
    if ( yourbookCode && yourbookCode.length) {
      loadDeckCode(yourbookCode.match(/.{1,2}/g),'YOU')
    }

    let allybookCode = query.get("allybook")
    if ( allybookCode && allybookCode.length ) {
      loadDeckCode(allybookCode.match(/.{1,2}/g),'ALLY')
    }



  }, [])

  const context = useContext(GlobalContext)

  const [showSpellbook, setShowSpellbook] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showTTSModal, setShowTTSModal] = useState(false)

  const [selectedCards, setSelectedCards] = useState([])
  const [selectedAllyCards, setSelectedAllyCards] = useState([])
  const [showSpellDetails, setShowSpellDetails] = useState(false)
  const [selectedSpellDetails, setSelectedSpellDetails] = useState()
  const [deckCodeUrl, setDeckCodeUrl] = useState('')
  const [yourTTSCode, setYourTTSCode] = useState('')
  const [allyTTSCode, setAllyTTSCode] = useState('')
  const [spellAnimations, setSpellAnimations] = useState([])
  const [showAllysSpellbook, setShowAllysSpellbook] = useState(false)
  const [position, setPosition] = useThrottle({x: 0, y:0},15)

  const [showPromoCards, setShowPromoCards] = useState(false)
  const [showDuoCards, setShowDuoCards] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  const [showSchool, setShowSchool] = useState({
    charm: true,
    curse: true,
    defense: true,
    divination: true,
    hex: true,
    jinx: true,
    transfiguration: true,
  })
  const [showLevel, setShowLevel] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
  })

  const [searchTerm, setSearchTerm] = useState('');
  const [cardSearch, setCardSearch] = useState('');

  const handleMouseMove = useCallback((e) => {
    setPosition({x: e.clientX, y: e.clientY})
  })

  const generateDeckCode = () => {
    if (validateSpellbook(selectedCards) === true ){
      const yourArray = selectedCards.map(s=>s.spellCode.toLowerCase()).join('')
      let shareUrl = ''
      if (showDuoCards === true && selectedAllyCards.length > 0) {
        if (validateSpellbook(selectedAllyCards) === true) {
          const allyArray = selectedAllyCards.map(s=>s.spellCode.toLowerCase()).join('')
          shareUrl = 'https://www.thatswizard.com/build-a-deck/?yourbook=' + encodeURIComponent(yourArray) + '&allybook=' + encodeURIComponent(allyArray)
        }
        else {
          return false
        }
      }
      else {
        shareUrl = 'https://www.thatswizard.com/build-a-deck/?yourbook=' + encodeURIComponent(yourArray)
      }
      setDeckCodeUrl(shareUrl)
      openShareModal()
    }
  }

  const generateTTSCode = () => {
    if (validateSpellbook(selectedCards) === true ){
      const yourTTSCode = '!deck ' + selectedCards.map(s=>s.spellCode.toLowerCase()).join('')
      setYourTTSCode(yourTTSCode)
      if (showDuoCards === true && selectedAllyCards.length > 0) {
        if (validateSpellbook(selectedAllyCards) === true) {
          const allyTTSCode = '!deck ' + selectedAllyCards.map(s=>s.spellCode.toLowerCase()).join('')
          setAllyTTSCode(allyTTSCode)
        }
        else {
          return false
        }
      }
      openTTSModal()
    }
  }

  const loadDeckCode = (deckCode, target) => {
    const deckCodeSpells = []
    for (const spell of cards) {
        if ( deckCode.includes(spell.spellCode.toLowerCase()) ) {
          deckCodeSpells.push(spell)
        }
    }

    const sortedDeckCodeSpells = sortSpellbook(deckCodeSpells)

    /* TODO: check ally deck code */
    if( !validateSpellbook(sortedDeckCodeSpells)){
      history.replace('/build-a-deck')
      return context.addNotification(`Deckcode is not valid.`, 'failure')
    }
    else {
      if( checkPromoCards(sortedDeckCodeSpells).length > 0){
        setShowPromoCards(true)
      }
      if ( target === 'YOU' ){
        setSelectedCards(sortedDeckCodeSpells)
      }
      if ( target === 'ALLY' ){
        setShowDuoCards(true)
        setSelectedAllyCards(sortedDeckCodeSpells)
      }
      return context.addNotification(`Deckcode loaded.`, 'success')

    }
  }

  const togglePromoCards = () => {
    if (showPromoCards === true) {
      if (checkPromoCards(selectedCards).length > 0) {
        return context.addNotification(`You still have promo cards in your spellbook.`, 'failure')
      }
      else if (checkPromoCards(selectedAllyCards).length > 0) {
        return context.addNotification(`You still have promo cards in ally's spellbook.`, 'failure')
      }
      else {
        setShowPromoCards(false)
      }
    }
    else {
      setShowPromoCards(true)
    }
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const toggleDuoCards = () => {
    if (showDuoCards === true) {
      if (checkDuoCards(selectedCards).length > 0) {
        return context.addNotification(`You still have duo cards in your spellbook.`, 'failure')
      }
      else {
        setSelectedAllyCards([]);
        setShowAllysSpellbook(false)
        setShowDuoCards(false)
      }

    }
    else {
      setShowDuoCards(true)
    }
  }

  const checkPromoCards = (checkPromoCardSpells) => {
    let fullCardList = []
    if (checkPromoCardSpells) {
      fullCardList = checkPromoCardSpells
    }
    else {
      if (showDuoCards === true) {
        fullCardList = [...selectedCards, ...selectedAllyCards]
      }
      else {
        fullCardList = [...selectedCards]
      }
    }

    return fullCardList.filter(s=>(s.promo===true))
  }

  const checkDuoCards = (checkDuoCardSpells) => {
    let fullCardList = []
    if (checkDuoCardSpells) {
      fullCardList = checkDuoCardSpells
    }
    else {
      if (showDuoCards === true) {
        fullCardList = [...selectedCards, ...selectedAllyCards]
      }
      else {
        fullCardList = [...selectedCards]
      }
    }
    return fullCardList.filter(s=>(s.duo===true))
  }

  const toggleSpellbook = () => {
    setShowAllysSpellbook(false)
    if (showSpellbook === true) {
      closeSpellbook()
    }
    if (showSpellbook === false) {
      openSpellbook()
    }
  }

  const toggleAllySpellbook = () => {
    setShowAllysSpellbook(true)
    if (showSpellbook === true) {
      closeSpellbook()
    }
    if (showSpellbook === false) {
      openSpellbook()
    }
  }

  const openSpellbook = () => {
    context.openModal()
    setShowSpellbook(true)
  }

  const closeSpellbook = () => {
    context.closeModal()
    setShowSpellbook(false)
  }

  const openShareModal = () => {
    context.openModal()
    setShowShareModal(true)
  }

  const openTTSModal = () => {
    context.openModal()
    setShowTTSModal(true)
  }

  const closeShareModal = () => {
    context.closeModal()
    setShowShareModal(false)
  }

  const closeTTSModal = () => {
    context.closeModal()
    setShowTTSModal(false)
  }

  const cards = [

      {
        spellId: 'Stun',
        spellCode: 'St',
        spellLevel: 1,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Energize',
        spellCode: 'Eg',
        spellLevel: 1,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',
        duo: true,
      },
      {
        spellId: 'Silence',
        spellCode: 'Si',
        spellLevel: 2,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Levitate',
        spellCode: 'Le',
        spellLevel: 3,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',
      },
    /*{
      spellId: 'Befuddle',
      spellCode: 'Be',
      spellLevel: 3,
      spellType: 'charm',
      promo: true,
      cardArt: 'Jamie Noble Frier',
    },*/
      {
        spellId: 'Confusion',
        spellCode: 'Co',
        spellLevel: 4,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',
      },


      {
        spellId: 'Paralyze',
        spellCode: 'Pa',
        spellLevel: 1,
        spellType: 'curse',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Explosion',
        spellCode: 'Ex',
        spellLevel: 2,
        spellType: 'curse',
        cardArt: 'Fodor Miklos',
      },
      {
        spellId: 'Embrace Pain',
        spellCode: 'EP',
        spellLevel: 3,
        spellType: 'curse',
        cardArt: 'Jamie Noble Frier',
      },
      /*{
        spellId: 'Enrage',
        spellCode: 'En',
        spellLevel: 3,
        spellType: 'curse',
        promo: true,
        cardArt: 'Jamie Noble Frier',
      },*/
      {
        spellId: 'Death',
        spellCode: 'De',
        spellLevel: 4,
        spellType: 'curse',
        cardArt: 'Jamie Noble Frier',
      },
      /*{
        spellId: 'Hide Soul',
        spellCode: 'HS',
        spellLevel: 4,
        spellType: 'curse',
        promo: true,
        cardArt: 'Jamie Noble Frier',
      },*/
      {
        spellId: 'Conflagration',
        spellCode: 'Cn',
        spellLevel: 4,
        spellType: 'curse',
        duo: true,
        cardArt: 'Jamie Noble Frier',
      },


      {
        spellId: 'Reflect',
        spellCode: 'Re',
        spellLevel: 1,
        spellType: 'defense',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Transference',
        spellCode: 'Tr',
        spellLevel: 1,
        spellType: 'defense',
        duo: true,
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Disarm',
        spellCode: 'Di',
        spellLevel: 2,
        spellType: 'defense',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Dispel',
        spellCode: 'Ds',
        spellLevel: 3,
        spellType: 'defense',
        cardArt: 'Jamie Noble Frier',
      },
      /*{
        spellId: 'Backlash',
        spellCode: 'Bl',
        spellLevel: 3,
        spellType: 'defense',
        promo: true,
        cardArt: 'Jamie Noble Frier',
      },*/
      {
        spellId: 'Invisibility',
        spellCode: 'Iv',
        spellLevel: 4,
        spellType: 'defense',
        cardArt: 'Jamie Noble Frier',
      },
      /*{
        spellId: 'Absorb Magic',
        spellCode: 'AM',
        spellLevel: 3,
        spellType: 'defense',
        promo: true,
        cardArt: 'Jamie Noble Frier',
      },*/


      {
        spellId: 'Portents',
        spellCode: 'Pr',
        spellLevel: 1,
        spellType: 'divination',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Scry',
        spellCode: 'Sc',
        spellLevel: 2,
        spellType: 'divination',
        cardArt: 'Jamie Noble Frier',
      },
      /*{
        spellId: 'Foresight',
        spellCode: 'Fs',
        spellLevel: 2,
        spellType: 'divination',
        promo: true,
        cardArt: 'Jamie Noble Frier',
      },*/
      {
        spellId: 'Arithmancy',
        spellCode: 'Ar',
        spellLevel: 3,
        spellType: 'divination',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Grim Omen',
        spellCode: 'GO',
        spellLevel: 4,
        spellType: 'divination',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Nevermore',
        spellCode: 'Nm',
        spellLevel: 4,
        spellType: 'divination',
        duo: true,
        cardArt: 'Simon Zhong',
      },


      {
        spellId: 'Share Pain',
        spellCode: 'sp',
        spellLevel: 1,
        spellType: 'hex',
        cardArt: 'Jamie Noble Frier',
      },
      /*{
        spellId: 'Drain Life',
        spellCode: 'DL',
        spellLevel: 1,
        spellType: 'hex',
        promo: true,
        cardArt: 'Jamie Noble Frier',
      },*/
      {
        spellId: 'Fear',
        spellCode: 'Fe',
        spellLevel: 2,
        spellType: 'hex',
        cardArt: 'Simon Zhong',
      },
      {
        spellId: 'Imprison',
        spellCode: 'Im',
        spellLevel: 2,
        spellType: 'hex',
        duo: true,
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Chaos',
        spellCode: 'Ch',
        spellLevel: 3,
        spellType: 'hex',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Mind Control',
        spellCode: 'MC',
        spellLevel: 4,
        spellType: 'hex',
        cardArt: 'Jamie Noble Frier',
      },


      {
        spellId: 'Shock',
        spellCode: 'So',
        spellLevel: 1,
        spellType: 'jinx',
        cardArt: 'Shunskis',
      },
      {
        spellId: 'Miasma',
        spellCode: 'Mi',
        spellLevel: 1,
        spellType: 'jinx',
        duo: true,
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Tongue Tie',
        spellCode: 'TT',
        spellLevel: 2,
        spellType: 'jinx',
        cardArt: 'Jamie Noble Frier',
      },
      /*{
        spellId: 'Slow',
        spellCode: 'Sl',
        spellLevel: 2,
        spellType: 'jinx',
        promo: true,
        cardArt: 'Jamie Noble Frier',
      },*/
      {
        spellId: 'Ignite',
        spellCode: 'Ig',
        spellLevel: 3,
        spellType: 'jinx',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Erase Memory',
        spellCode: 'EM',
        spellLevel: 4,
        spellType: 'jinx',
        cardArt: 'Jamie Noble Frier',
      },


      {
        spellId: 'Regenerate',
        spellCode: 'Rg',
        spellLevel: 1,
        spellType: 'transfiguration',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Brittlebones',
        spellCode: 'Br',
        spellLevel: 2,
        spellType: 'transfiguration',
        cardArt: 'Jamie Noble Frier',
      },
      /*{
        spellId: 'Shillelagh',
        spellCode: 'Sg',
        spellLevel: 2,
        spellType: 'transfiguration',
        promo: true,
        cardArt: 'Jamie Noble Frier',
      },*/
      {
        spellId: 'Polymorph',
        spellCode: 'Po',
        spellLevel: 3,
        spellType: 'transfiguration',
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Molten Floor',
        spellCode: 'MF',
        spellLevel: 3,
        spellType: 'transfiguration',
        duo: true,
        cardArt: 'Jamie Noble Frier',
      },
      {
        spellId: 'Shrink',
        spellCode: 'Sh',
        spellLevel: 4,
        spellType: 'transfiguration',
        cardArt: 'Jamie Noble Frier',
      },

  ]

  const toggleSchool = (schoolName) => {
    setShowSchool(prevState => ({...prevState,  [`${schoolName}`]: !showSchool[schoolName]}))
  }

  const toggleLevel = (level) => {
    setShowLevel(prevState => ({...prevState,  [`${level}`]: !showLevel[level]}))
  }

  const handleSearchChange = (e) => {

      setCardSearch(e.target.value);

  };


  const searchTermEnter = (e) => {
    console.log(e.key);
    if (e.key == 'Enter') { // Check if Enter is pressed
      console.log("enter was pressed", e.key);
      setSearchTerm(e.target.value);
    }

  };

  const enterSearch = () => {
    setSearchTerm(cardSearch);
  }

  const clearSchool = () => {
    setShowSchool({
      charm: true,
      curse: true,
      defense: true,
      divination: true,
      hex: true,
      jinx: true,
      transfiguration: true,
    })
  }

  const clearLevel = () => {
    setShowLevel({
      1: true,
      2: true,
      3: true,
      4: true,
    })
  }

  const clearDuos = () => {
    setShowDuoCards(false)
  }

  const clearPromos = () => {
    setShowPromoCards(false)
  }


  const clearSearch = () => {
    setCardSearch('');
    setSearchTerm('');
  }

  const clearAll = () => {
    clearSchool()
    clearLevel()
    clearDuos()
    clearPromos()
    clearSearch()
  }

  const renderCards = () => {
    let cardArray = []
    let filteredCount = 0;

    for (const spell of cards) {

      let showSpell = true

      if ((spell.promo && !showPromoCards)) {
        showSpell = false
      }
      if ((spell.duo && !showDuoCards)) {
        showSpell = false
      }
      if ((!showSchool[spell.spellType] )) {
        showSpell = false
      }
      if ((!showLevel[spell.spellLevel] )) {
        showSpell = false
      }
      if ((searchTerm.length > 0 && (!spell.spellId.toLowerCase().includes(searchTerm.toLowerCase())) )) {
        showSpell = false
      }

      if (showSpell) {
        filteredCount++;
        cardArray.push(
          <div key={spell.spellId}>
          {renderSpell(spell)}
          </div>
        )
      }

    }
    return (
      <>
      <div className="card-selection-current-filter">
        {/* Display header with the number of spells */}
        <div className="card-selection-filter-totals">Showing {filteredCount} out of {cards.length} spells</div>
        {renderCurrentSchoolFilters()}
        {renderCurrentLevelFilters()}
        {renderCurrentDuoFilter()}
        {renderCurrentPromosFilter()}
        {renderCurrentSearchFilter()}
        {renderClearFilterButton()}

      </div>
      <div className="card-selection-container">{cardArray}</div>
      </>
    )
  }

  const renderCurrentSchoolFilters = () => {
    const schoolFilters =  Object.keys(showSchool)
      .filter((school) => showSchool[school] === true) // Filter out false schools
      .map((school) => (
        <span key={school} className={`icon-${school}`}></span> // Return a span for each active school
      ));

      if (schoolFilters.length < 7) {
        return <div className="orange-button card-selection-current-filter-button" onClick={clearSchool}>School{schoolFilters.length > 1 ? 's':''}: {schoolFilters} <span className="card-selection-remove-filter-button icon-clear"></span></div>
      }
      return
  };

  const renderCurrentLevelFilters = () => {
    const levelFilters =  Object.keys(showLevel)
      .filter((level) => showLevel[level] === true) // Filter out false schools
      .map((level) => (
        <span key={level} >{level}</span> // Return a span for each active school
      ));

    if (levelFilters.length < 4) {
      return <div className="card-selection-current-filter-button" onClick={clearLevel}>Level{levelFilters.length > 1 ? 's':''}: {levelFilters} <span className="card-selection-remove-filter-button icon-clear"></span></div>
    }
    return
  };

  const renderCurrentDuoFilter = () => {
    if (showDuoCards) {
      return <div className="card-selection-current-filter-button" onClick={clearDuos}>Duos: Yes <span className="card-selection-remove-filter-button icon-clear"></span></div>
    }
    return
  };

  const renderCurrentPromosFilter = () => {
    if (showPromoCards) {
      return <div className="card-selection-current-filter-button" onClick={clearPromos}>Promos: Yes <span className="card-selection-remove-filter-button icon-clear"></span></div>
    }
    return
  };

  const renderCurrentSearchFilter = () => {
    if (searchTerm.length > 0) {
      return <div className="card-selection-current-filter-button" onClick={clearSearch}>"{searchTerm}" <span className="card-selection-remove-filter-button icon-clear"></span></div>
    }
    return
  };

  const renderClearFilterButton = () => {

    const schoolFilters =  Object.keys(showSchool)
      .filter((school) => showSchool[school] === true) // Filter out false schools


    const levelFilters =  Object.keys(showLevel)
      .filter((level) => showLevel[level] === true) // Filter out false schools

    let numFilters = 0
    if (schoolFilters.length < 7) numFilters++
    if (levelFilters.length < 4) numFilters++
    if (showDuoCards) numFilters++
    if (showPromoCards) numFilters++
    if (searchTerm.length > 0) numFilters++


    if (numFilters > 1) {
      return <div className="card-selection-current-reset-filter-button" onClick={clearAll}>Clear all <span className="icon-loop"></span></div>
    }
    return
  };

  const renderSpell = (spell) => {
    const pathName = spell.spellId.replace(/\s/g, '').toLowerCase()
    const spellImage = require(`./assets/${pathName}.jpg`)
    return (
      <div key={spell.spellId}  className={`spell-selection-container`}>
        <div className="spell-selection-header">
          <div className="spell-selection-title">{spell.spellId} {spell.duo ? '(Duo)':''} {spell.promo ? '(Promo)':''}</div>
          <div className="spell-selection-type"> <span className={`school-icon icon-${spell.spellType}`}></span><span className="spell-selection-subtitle">{spell.spellLevel}</span></div>
        </div>
        <div className="spell-selection-image-container">

          <img className={renderSpellImage(spell)}
               src={spellImage}
               onClick={()=>toggleSpell(spell)}/>
          <div className="selected-spell-button clickable spell-select-info-button" onClick={() => openSpellDetails(spell)}>i</div>
        </div>
      </div>)
  }

  const renderSpellImage = (spell) => {
    if (hasSpell(spell)) {
      return ('spell-image spell-selected')
    }
    return 'spell-image'
  }

  const toggleSpell = (spell) => {
    if (hasSpell(spell)) {
      removeSpell(spell)
    }
    else {
      addSpell(spell)
    }
  }

  const validateSpellbook = (spells) => {

    let valid = true
    if (spells.length !== 7) {
      context.addNotification(`Spellbooks must have 7 spells.`, 'failure')
      return valid = false
    }

    spells.forEach(spell=> {
      if (hasDuplicateSpells(spell,spells) > 1) {
        context.addNotification(`Spellbooks cannot have duplicate spells.`, 'failure')
        return valid = false
      }
      if (hasDuplicateLevels(spell, spells) > 1) {
        context.addNotification(`Spellbook already has a level ${spell.spellLevel} <span class="missing-icon icon-${spell.spellType}"></span>`, 'failure')
        return valid = false
      }
      if (spell.spellLevel > 1) {
        const missingSpellLevels = checkDeckRequirements(spell, spells)
        if (missingSpellLevels.length) {
          context.addNotification(`Spellbook is invalid.`, 'failure')
          return valid = false
        }
      }
    })
    return valid
  }

  const hasDuplicateSpells = (spell,spells) => {
    return spells.filter(s => s.spellId === spell.spellId)
  }

  const hasDuplicateLevels = (spell, spells) => {
    return spells.filter(s => (s.spellLevel === spell.level && s.spellType === spell.spellType))
  }

  const checkDeckRequirements = (spell, spells) => {
    let requiredSpellLevels = []
    for (let i=spell.spellLevel-1; i>0; i--) {
      if (!deckHasLevel(spell,i, spells)) {
        requiredSpellLevels.unshift(i)
      }
    }
    return requiredSpellLevels
  }

  const deckHasLevel = (spell, level, spells) => {
    return spells.find(s => (s.spellLevel === level && s.spellType === spell.spellType))
  }

  const getCurrentSpellbook = () => {
    let currentSpellList = []
    if ( showAllysSpellbook === true ) {
      return selectedAllyCards
    }
    else {
      return selectedCards
    }
  }

  const addSpell = (spell) => {

    let currentSpellList = getCurrentSpellbook()

    if (hasSpell(spell)) {
      return context.addNotification(`${spell.spellId} is already taken.`, 'failure')
    }

    if (currentSpellList.length === 7) {
      return context.addNotification(`Spellbook already has seven spells.`, 'failure')
    }

    if (hasLevel(spell,spell.spellLevel)) {
      return context.addNotification(`Spellbook already has a <span class="missing-icon icon-${spell.spellType}"></span> ${spell.spellLevel}`, 'failure')
    }

    if (spell.spellLevel > 1) {
      const missingSpellLevels = checkRequirements(spell)
      if (missingSpellLevels.length) {
        return context.addNotification(`Spellbook is missing a  ${arrayToSentence(missingSpellLevels)}.`, 'failure');
      }
    }
    if (showAllysSpellbook === true) {
      setSelectedAllyCards([...selectedAllyCards, spell])
      addSpellAnimation(spell, 'ALLY')
    }
    else {
      setSelectedCards([...selectedCards, spell])
      addSpellAnimation(spell, 'YOU')
    }

    // return context.addNotification(`${spell.spellId} was added to your spellbook.`, 'success')

  }

  const checkRequirements = (spell) => {
    let requiredSpellLevels = []
    for (let i=spell.spellLevel-1; i>0; i--) {
      if (!hasLevel(spell,i)) {
        let missingSpellStr = `<span class="missing-icon icon-${spell.spellType}"></span> ${i}`;
        requiredSpellLevels.unshift(missingSpellStr)
      }
    }
    return requiredSpellLevels
  }

  const hasLevel = (spell, level) => {
    let currentSpellList = getCurrentSpellbook()
    return currentSpellList.find(s => (s.spellLevel === level && s.spellType === spell.spellType))
  }

  const hasSpell = (spell) => {
    let currentSpellList = [...selectedCards, ...selectedAllyCards]
    return currentSpellList.find(s => s.spellId === spell.spellId)
  }

  const removeSpell = (spell) => {
    let currentSpellList = getCurrentSpellbook()
    const lockedSpells = dependentSpells(spell).map(spell => spell.spellId)
    if (lockedSpells.length) {
      return context.addNotification(`${spell.spellId} is required for ${arrayToSentence(lockedSpells)}.`, 'failure')
    }
    const newSpells = currentSpellList.filter(s => (s.spellId !== spell.spellId))
    if (showAllysSpellbook === true) {
      setSelectedAllyCards(newSpells)
    }
    else {
      setSelectedCards(newSpells)
    }

    // return context.addNotification(`${spell.spellId} was removed from your spellbook.`, 'success')


  }

  const dependentSpells = (spell) => {
    let currentSpellList = getCurrentSpellbook()
    return currentSpellList.filter(s => {
      return ( s.spellType === spell.spellType && s.spellLevel > spell.spellLevel)
    })
  }

  const arrayToSentence = (arr) => {
    return arr.slice(0, -2).join(', ') +
      (arr.slice(0, -2).length ? ', ' : '') +
      arr.slice(-2).join(arr.length === 2 ? " and " : ", and ");
  }

  const sortSpellbook = (spells) => {
    const sortedSpellbook = [...spells].sort(function(a,b) {
      var schoolA = a.spellType.toLowerCase();
      var schoolB = b.spellType.toLowerCase();

      var levelA = a.spellLevel;
      var levelB = b.spellLevel;

      if (schoolA < schoolB) return -1;
      if (schoolA > schoolB) return 1;
      if (levelA < levelB) return -1;
      if (levelA > levelB) return 1;
    })
    return sortedSpellbook
  }

  const renderSelectedCards = () => {
    let currentSpellList = getCurrentSpellbook()
    const sortedSpellbook = sortSpellbook(currentSpellList)

    return sortedSpellbook.map((spell) => {
      return renderSelectedSpell(spell)
    })
  }

  const renderSelectedSpell = (spell) => {
    const pathName = spell.spellId.replace(/\s/g, '').toLowerCase()
    const spellImage = require(`./assets/${pathName}.jpg`)
    return (
      <div key={spell.spellId}>
        <div className="spell-selection-container">

          <div className="spell-selection-header spell-selection-header-white">
            <div className="spell-selection-title">{spell.spellId} {spell.duo ? '(Duo)':''} {spell.promo ? '(Promo)':''}</div>
            <div className="spell-selection-type"> <span className={`school-icon icon-${spell.spellType}`}></span><span className="spell-selection-subtitle">{spell.spellLevel}</span></div>
          </div>

          <div className="spell-selection-image-container">
            <img className="spell-image" src={spellImage} onDoubleClick={() => removeSpell(spell)} />
            <div className="selected-spell-button spell-select-info-button clickable" onClick={() => openSpellDetails(spell)}>i</div>
            <div className="selected-spell-button spell-select-remove-button clickable" onClick={() => removeSpell(spell)}><span className="icon-clear"/></div>
          </div>

        </div>
      </div>)
  }

  const resetSpellbook = () => {
    setSelectedCards([]);
    setSelectedAllyCards([]);
    context.addNotification(`Spellbooks reset.`, 'success')
    return history.replace('/build-a-deck')
  }

  const renderSpellbookHeader = () => {

      return (

          <div className="spellbook-header-container">
            <div className="spellbook-header-button clickable" onClick={()=>generateDeckCode()}>Share</div>
            <div className="spellbook-header-button clickable" onClick={()=>resetSpellbook()}>Reset</div>
            {/*<div className="spellbook-header-button clickable" onClick={()=>generateTTSCode()}>TS Code</div>*/}
          </div>

      )

  }

  const openSpellDetails = (spell) => {
    setSelectedSpellDetails(spell)
    context.openModal()
    setShowSpellDetails(true)
  }

  const closeSpellDetails = () => {
    setSelectedSpellDetails(null)
    context.closeModal()
    setShowSpellDetails(false)
  }

  const renderSpellDetails = (spell) => {
    const pathName = spell.spellId.replace(/\s/g, '').toLowerCase()
    const spellImage = require(`./assets/${pathName}.jpg`)
    return (
      <div className="spell-details-container">
        <img className="spell-details-image" src={spellImage}/>
        <div className="spell-details-artist">Artist: {spell.cardArt}</div>
      </div>)
  }

  const renderSpellAnimations = () => {
    return (
      <div className="spellbook-animation-container">
        {spellAnimations.map(
          (spell) =>
            <SpellAnimationElement
              key={spell.spellKey}
              closeHandler={() => removeSpellAnimation(spell)}
              spellImage={spell.spellImage}
              spellOwner={spell.spellOwner}
            />

        )}
      </div>
    )
  }

  const addSpellAnimation = (spell, spellOwner) => {
    const newSpellAnimation = {
      spellImage:spell.spellId.replace(/\s/g, '').toLowerCase(),
      spellKey: new Date().getTime(),
      spellOwner: spellOwner
    }
    const updatedSpellAnimationsArray = [newSpellAnimation, ...spellAnimations]
    setSpellAnimations(updatedSpellAnimationsArray)

  }

  const removeSpellAnimation = (spell) => {
    let updatedSpellAnimationsArray = [...spellAnimations]
    const removeIndex = updatedSpellAnimationsArray.indexOf(spell)
    updatedSpellAnimationsArray.splice(removeIndex, 1)
    setSpellAnimations(updatedSpellAnimationsArray)
  }

  const renderSchoolFilters = () => {
    let filterArray = []
    for (const school in showSchool) {
      filterArray.push(
        <div key={school} className="tooltip-container">
          <button className={`tooltip-button card-selection-filter-button ${showSchool[school] ? 'card-selection-school-filter-selected' : ''}`}    onClick={() => toggleSchool(school)}>
            <span className={`school-icon icon-${school}`}></span>
            <span className="tooltip-text">{school}</span>
          </button>
        </div>
      )
    }
    return filterArray
  }

  const renderSearchActionButton = () => {

    if (searchTerm.length > 0) {
      return <span className="card-selection-text-filter-reset-button icon-clear" onClick={clearSearch}></span>
    }
    else {
      return <span className="card-selection-text-filter-reset-button icon-search" onClick={enterSearch}></span>
    }


  }



  return (
    <>
    <section className="header-section spellbook-header">
      <img className="header-logo" src={logo} alt="That's Wizard"/>
      {/*<a href="https://www.kickstarter.com/projects/matthewsorrentino/thats-wizard" target="_blank">
        <img className="now-live-logo" src={nowLive} alt="Now Live!" />
      </a>*/}
    </section>

    <div className='content-bg'>
      <div className="content-border">
        <div className="section-container">
          <section className="cards-container">


            <div className="card-selection-header">
              <div className="card-selection-title">Build a Deck</div>
              <div className="card-selection-filter-container">
                <div className="icon-equalizer" onClick={toggleFilters}></div>
              </div>
            </div>


            <div className={`card-selection-header-container ${!showFilters ? 'hidden':''}`}>



              <div className="card-selection-header-filter-container">
                <div className="card-selection-header-filter-title">Schools of magic</div>
                <div className="card-selection-header-filter-content"> {renderSchoolFilters()}</div>
              </div>

              <div className="card-selection-header-filter-container">
                <div className="card-selection-header-filter-title">Spell level</div>
                <div className="card-selection-header-filter-content">
                <button className={`card-selection-filter-button ${showLevel[1] ? 'card-selection-school-filter-selected' : ''}`}    onClick={() => toggleLevel(1)}>
                  1
                </button>
                <button className={`card-selection-filter-button ${showLevel[2] ? 'card-selection-school-filter-selected' : ''}`}    onClick={() => toggleLevel(2)}>
                  2
                </button>
                <button className={`tooltip-button card-selection-filter-button ${showLevel[3] ? 'card-selection-school-filter-selected' : ''}`}    onClick={() => toggleLevel(3)}>
                  3
                </button>
                <button className={`tooltip-button card-selection-filter-button ${showLevel[4] ? 'card-selection-school-filter-selected' : ''}`}    onClick={() => toggleLevel(4)}>
                  4
                </button>
                </div>
              </div>

              <div className="card-selection-header-filter-container">
                <div className="card-selection-header-filter-title">Duos</div>
                <div className="card-selection-header-filter-content">
                  <button className={`tooltip-button card-selection-filter-button ${showDuoCards ? 'card-selection-school-filter-selected' : ''}`}   onClick={toggleDuoCards}><span className="icon-duos"></span></button>
                </div>
              </div>


              <div className="card-selection-header-filter-container">
                <div className="card-selection-header-filter-title">Search</div>
                <div className="card-selection-header-filter-content">
                  <div className="card-selection-text-filter-container">
                  <input className="card-selection-text-filter" type="text" placeholder="Search" value={cardSearch} onKeyDown={(e) => searchTermEnter(e)} onChange={(e) => handleSearchChange(e)} />
                    {renderSearchActionButton()}
                  </div>
                </div>
              </div>

{/*

              <div className="card-selection-header-filter-container">
                <div className="card-selection-header-filter-title">Promos</div>
                <div className="card-selection-header-filter-content">
                  <button className={`tooltip-button card-selection-filter-button ${showPromoCards ? 'card-selection-school-filter-selected' : ''}`}   onClick={togglePromoCards}>Ok </button>
                </div>
              </div>
*/}



            </div>


            <div className="cards" onMouseMove={handleMouseMove}>

              <div className="cards-instructions">Click on a card to add it to your spellbook, click again to remove it.</div>
              <div className="cards-instructions">View your spellbook through the bottom bar.</div>
                  {renderCards()}


            </div>

          </section>
        </div>
      </div>
    </div>

    <div  className={`spellbook-overlay-container ${showSpellbook ? 'show-spellbook-overlay' : ''}`}
          onClick={closeSpellbook}>
    </div>

    <div className={`spellbook-container ${showSpellbook ? 'show-spellbook' : ''}`} >

      <div className="icon-book spellbook-icon" onClick={toggleSpellbook}>
        <div className="spellbook-amount">{selectedCards.length}</div>
        <div className={`spellbook-chevron  clickable ${!showSpellbook ? 'icon-chevron-up' : 'icon-chevron-down'}`}/>
      </div>

      <div className="spellbook-cards-container blue-scrollable">
        {renderSpellbookHeader()}
        <div className="card-selection-container">
        {renderSelectedCards()}
        </div>
      </div>

    </div>



    {renderSpellAnimations()}

    <div className={`spellbook-modal-container ${showSpellDetails ? 'show-modal' : ''}`}>
      <div className="spellbook-modal-background" onClick={()=> closeSpellDetails()}/>
      <div className="spellbook-modal-content blue-scrollable">
        {selectedSpellDetails ? renderSpellDetails(selectedSpellDetails) : ''}
      </div>
      <div className={`close-button icon-clear ${showSpellDetails ? 'show-modal-close' : ''}`} onClick={closeSpellDetails}/>
    </div>

    <div className={`spellbook-modal-container ${showShareModal ? 'show-modal' : ''}`}>
      <div className="spellbook-modal-background" onClick={closeShareModal}/>
      <div className="spellbook-modal-content blue-scrollable">
        <div className="share-container">
          <div className="share-container-header">Share your spellbook with your fellow witches and wizards!</div>
          <div className="deck-code-input" type="text">{deckCodeUrl}</div>
          <div className="share-button-facebook">
            <FacebookShareButton url={deckCodeUrl} quote="Get your wands ready to duel and check out this custom spellbook for That's Wizard! Create your own and learn more about the game! @thatswizard">
              <div className="share-spellbook-button icon-facebook"/>
            </FacebookShareButton>
          </div>
          <div className="share-button-twitter">
            <TwitterShareButton url={deckCodeUrl} title="Get your wands ready to duel and check out this custom spellbook for That's Wizard! Create your own and learn more about the game!" via="manaworksgames" >
              <div className="share-spellbook-button icon-twitter"/>
            </TwitterShareButton>
          </div>
        </div>
      </div>
      <div className={`close-button icon-clear ${showShareModal ? 'show-modal-close' : ''}`} onClick={closeShareModal}/>
    </div>

    <div className={`spellbook-modal-container ${showTTSModal ? 'show-modal' : ''}`}>
      <div className="spellbook-modal-background" onClick={closeTTSModal}/>
      <div className="spellbook-modal-content blue-scrollable">
        <div className="tts-container">
          <div className="share-container-header">Import your spellbooks into Tabletop Simulator!</div>
          <p className="tts-instructions">To use this deck code you will type in the <span className="bold-text">!deck</span> command into the chat window of TS, followed by the unique code for your spellbook.</p>
          <p className="tts-instructions">Don't worry, <span className="bold-text">!deck</span> commands will not be broadcast to the other players, so you will not give away what spells you took.</p>
          <p className="tts-instructions">The seven cards will then automatically be dealt into your hand as long as you are at a seat.</p>
          <div className="tts-code-input-label">Your Spellbook</div>
          <div className="tts-code-input" type="text">{yourTTSCode}</div>
          <div className={`tts-code-input-label ${!showDuoCards ? 'tts-code-input-hidden' : ''}`}>Ally's Spellbook</div>
          <div className={`tts-code-input ${!showDuoCards ? 'tts-code-input-hidden' : ''}`} type="text">{allyTTSCode}</div>
        </div>
      </div>
      <div className={`close-button icon-clear ${showTTSModal ? 'show-modal-close' : ''}`} onClick={closeTTSModal}/>
    </div>

    </>
  )
}

export default Deck
