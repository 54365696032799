import React, { useContext, useState } from 'react'
import { GlobalContext } from '../../app'


import './signup.css'

function Signup () {

  const context = useContext(GlobalContext)

  const [emailValue, setEmailValue] = useState('')
  const [firstnameValue, setFirstnameValue] = useState('')
  const [lastnameValue, setLastnameValue] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)


  const handleEmailInputChange = event => {
    event.preventDefault()
    setEmailValue(event.target.value)
  }

  const handleFirstnameInputChange = event => {
    event.preventDefault()
    setFirstnameValue(event.target.value)
  }

  const handleLastnameInputChange = event => {
    event.preventDefault()
    setLastnameValue(event.target.value)
  }



  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const submitEmail = () => {

    if (isSubmitting) return

    setIsSubmitting(true)

    if ( !emailValue.length ) {
      setIsSubmitting(false)
      context.addNotification('Please enter your email address.', 'failure')
      return
    }

/*    if ( !firstnameValue.length ) {
      setIsSubmitting(false)
      context.addNotification('Please enter your first name.', 'failure')
      return
    }*/

    if ( !context.isRealUser ) {
      setIsSubmitting(false)
      context.addNotification('Please retry in a moment.', 'failure')
      return
    }

    fetch("/subscribe",{
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Origin": "*"
      },
      body: JSON.stringify({
        'email': emailValue,
        'firstname': firstnameValue,
        'lastname': lastnameValue,
      })
    })
      .then((res) => {
        setIsSubmitting(false)
        res.json().then((res) => {
          handleMailResponse(res)
        });
      })
      .catch((err) => {
        console.log(err)
        setIsSubmitting(false)
      });

  }

  const handleMailResponse = (res) => {
    if ( res.message.status && res.message.status === 400 ) {
      const errors = res.message.errors;
      if (errors && errors.length) {
        if (errors[0].field === 'email_address' && errors[0].message === 'This value should not be blank.'){
          setIsSubmitting(false)
          context.addNotification('Email address is required.', 'failure')
          return
        }
      }

      if (res.message.detail) {
        if (res.message.detail.includes('please enter a real email address.')){
          setIsSubmitting(false)
          context.addNotification('Please enter a real email address.', 'failure')
          return
        }
        if (res.message.detail.includes('Use PUT to insert or update list members.')){
          setIsSubmitting(false)
          context.addNotification('You have already subscribed.', 'failure')
          return
        }
        setIsSubmitting(false)
        context.addNotification(res.message.detail, 'failure')
        return
      }

    }

    if ( res.message.statusCode && res.message.statusCode === 200 ) {
      const {status} = res.message;
      if (status && (status === 'subscribed' || status === 'pending')) {
        setIsSubmitting(false)
        context.addNotification('Thank you for subscribing!', 'success')
        return
      }
    }
  }

  return (
    <div className="email-signup constrained">

      <form className="form-input-container" noValidate onSubmit={handleSubmit}>

        <div className="firstname-container">
        <label className="form-label" htmlFor="firstname" id="firstname-label">First  Name *</label>
        <input type="text" id="firstname" placeholder="First Name" onChange={handleFirstnameInputChange} />
        </div>

        <div className="lastname-container">
        <label className="form-label" htmlFor="lastname" id="lastname-label">Last  Name</label>
        <input type="text" id="lastname" placeholder="Last Name (Optional)" onChange={handleLastnameInputChange} />
        </div>

        <div className="email-container">
        <label className="form-label" htmlFor="email" id="email-label">Email *</label>
        <input type="email" id="email" placeholder="example@domain.com" onChange={handleEmailInputChange} />
        </div>

        <button className='clickable'  id="email-button" type="submit" disabled={isSubmitting} onClick={submitEmail}>Subscribe</button>

      </form>

    </div>
  )
}

export default Signup
