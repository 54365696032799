import React from 'react'

import './footer.css'

function Footer () {

  return (
    <div className="footer-bg">
    <footer className="footer-section">
      <h3>Join the conversation on Facebook and Discord!</h3>

      <div className="social-media-container">
        <a target="_blank" href="https://www.facebook.com/groups/thatswizard/" className="social-media-icon">
          <span className='icon-facebook'/>
        </a>
        <a target="_blank" href="https://discord.gg/rGHsUPGSSJ" className="social-media-icon">
          <span className='icon-discord' />
        </a>
      </div>

      <div className="copyright-container">
        &copy; 2020 Manaworks Games LLC
      </div>

      <div className="legal-container">
        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>

    </footer>
    </div>
  )
}

export default Footer
