import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'

import './app.css';
import Home from './pages/home/home'
import Learn from './pages/learn/learn'
import Deck from './pages/deck/deck'
import Footer from './components/footer/footer'
import Notifications from './components/notifications/notifications'
import Navigation from './components/navigation/navigation'
import ScrollToTop from './components/scrollToTop/scrollToTop'


export const GlobalContext = React.createContext()

function App() {

  useEffect(() => {
    getScrollbarWidth()
    startGoogleRecaptcha()
  }, []);

  const [notifications, settNotificationArray] = useState([])
  const [modalCount, setModalCount] = useState(0)

  const startGoogleRecaptcha = () => {
    loadReCaptcha("6LdrmJMUAAAAACLjpySbOAKtDgz6rdatLKQHWT_S")
  }

  const getScrollbarWidth = () => {
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);

// Get the scrollbar width
    let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    scrollbarWidth = scrollbarWidth + 'px';
    document.documentElement.style.setProperty('--scroll-width', scrollbarWidth);

// Delete the DIV
    document.body.removeChild(scrollDiv);
  }

  const verifyCallback = (recaptchaToken) => {
    if(recaptchaToken.length) {
      setIsRealUser(true)
    }
  }

  const [isRealUser, setIsRealUser] = useState(false)

  const addNotification = (notificationText, notificationType = '') => {
    const newNotification = {
      notificationText,
      notificationType,
      notificationKey: new Date().getTime()
    }
    const updatedNotificationsArray = [...notifications, newNotification]
    settNotificationArray(updatedNotificationsArray)

  }

  const removeNotification = (notificationObject) => {
    let notificationsArrayCopy = [...notifications]
    const removeIndex = notificationsArrayCopy.indexOf(notificationObject)
    notificationsArrayCopy.splice(removeIndex, 1)
    settNotificationArray(notificationsArrayCopy)
  }

  const openModal = () => {
    if (modalCount === 0) {
      document.body.classList.add("no-scroll")
    }
    const newModalCount = modalCount + 1
    setModalCount(newModalCount)
  }

  const closeModal = () => {
    const newModalCount = modalCount- 1
    if (newModalCount === 0) {
      document.body.classList.remove("no-scroll")
    }
    setModalCount(newModalCount)
  }

  const globalValues = {
    notifications,
    addNotification,
    removeNotification,
    isRealUser,
    modalCount,
    openModal,
    closeModal,
  }


  return (
    <>
      <ReCaptcha sitekey="6LdrmJMUAAAAACLjpySbOAKtDgz6rdatLKQHWT_S" action='action_name' verifyCallback={verifyCallback} />
      <GlobalContext.Provider value={globalValues}>
        <Router>
          <ScrollToTop>
            <main>
              <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/how-to-play" exact component={Learn}/>
                <Route path="/build-a-deck/:deckcode?" exact component={Deck}/>
                <Route path="*">
                  <Redirect to="/"/>
                </Route>
              </Switch>
            </main>
            <Footer/>
            <Navigation/>
            <Notifications/>
          </ScrollToTop>
        </Router>
      </GlobalContext.Provider>
    </>
  );
}

export default App;
