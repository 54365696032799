import './navigation.css'

import React, {useState} from 'react';
import { Link } from 'react-router-dom'

const Navigation = () => {

  const [openMenu, setOpenMenu] = useState(false)

  return (
    <>
      <nav className={`navigation-container ${openMenu ? 'menu-open' : ''}`} >
        <Link to="/" className="nav-item clickable" onClick={()=>setOpenMenu(false)}>
          <h3>About</h3>
        </Link>
        <Link to="/how-to-play" className="nav-item clickable" onClick={()=>setOpenMenu(false)}>
          <h3>How to play</h3>
        </Link>
        <Link to="/build-a-deck" className="nav-item clickable" onClick={()=>setOpenMenu(false)}>
          <h3>Build a Deck</h3>
        </Link>
      </nav>
      <div className="nav-menu-button icon-menu" onClick={()=>setOpenMenu(true)}/>
      <div className={`close-button icon-clear ${openMenu ? 'show-nav-close' : ''}`} onClick={()=>setOpenMenu(false)}/>
    </>
  )
}

export default Navigation
