import './notifications.css'

import React, { useContext } from 'react'
import { GlobalContext } from '../../app'

import NotificationElement from './notificationElement'

const Notifications = () => {

  const context = useContext(GlobalContext)

  return (
    <div className='notifications-container'>
      {context.notifications.map(
        (notification) =>
          <NotificationElement
            key={notification.notificationKey}
            closeHandler={() => context.removeNotification(notification)}
            text={notification.notificationText}
            type={notification.notificationType}
          />
      )}
    </div>
  )
}

export default Notifications
