import React from 'react'

const SpellAnimationElement = (props) => {
  const spellImage = require(`./assets/${props.spellImage}-thumb.jpg`)
  return (
    <div className={`spell-animation ${props.spellOwner === 'ALLY' ? 'animate-ally-spell' : ''}`}
         onAnimationEnd={props.closeHandler}>
      <img src={spellImage} />
    </div>
  )
}

export default SpellAnimationElement
